<template>
  <v-container>
    <div class="header">
      <span>
        <h3>Criar uma nova etapa</h3>
        <p style="text-align: left">Segue abaixo o formuário de cadastro. Preencha com atenção.</p>
      </span>
      <div class="grow"/>
      <button type="button" class="btn-voltar" @click="$router.go(-1)">voltar</button>
    </div>
    <form class="formulario" autocomplete='off'>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="nome"
            name="nome"
            label="Nome Completo"
            :error-messages="erros.nome"
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="qtdedias"
            type="number"
            @mousedown="(e) => selecionarcampo(e)"
            name="qtdedias"
            :error-messages="erros.qtdedias"
            label="Quantidade de dias"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>      
      <v-row>
        <v-col
          class="d-flex"
          cols="12"
          sm="6"
        >

            
              <v-autocomplete
                @mousedown="(e) => selecionarcampo(e, 'gadget')"
                :items="gadgets"
                label="Gadget"
                name="gadget"
                v-model="gadget"
                :error-messages="erros.gadget"
                outlined
        ></v-autocomplete>
        </v-col>
        <v-col
          class="d-flex"
          cols="12"
          sm="6"
        >
            
             <v-autocomplete
                @mousedown="(e) => selecionarcampo(e, 'destino')"
                :items="destinos"
                label="Destino"
                name="destino"
                v-model="destino"
                :error-messages="erros.destino"
                outlined
        ></v-autocomplete>
        </v-col>
      </v-row>  
      <v-row>
        <v-col
          class="d-flex"
          cols="12"
          sm="12"
        >
            
              <v-select
                @mousedown="(e) => selecionarcampo(e, 'notificar')"
                :items="notificartipos"
                label="Notificar"
                name="notificar"
                v-model="notificar"
                :error-messages="erros.notificar"
                outlined
        ></v-select>
        </v-col>
      </v-row> 
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="tituloemail"
            :error-messages="erros.tituloemail"
            name="tituloemail"
            label="Titulo do Email"
            outlined
          ></v-text-field>
        </v-col>
        
      </v-row>    
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="textoemail"
            :error-messages="erros.textoemail"
            name="textoemail"
            label="Texto do Email"
            outlined
          ></v-text-field>
        </v-col>
        
      </v-row> 
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="textobotaoacao"
            :error-messages="erros.textobotaoacao"
            name="textobotaoacao"
            label="Texto Botao Ação"
            outlined
          ></v-text-field>
        </v-col>
        
      </v-row> 

      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="textourlbotao"
            :error-messages="erros.textourlbotao"
            name="textourlbotao"
            label="URL Botao Ação"
            outlined
          ></v-text-field>
        </v-col>
        
      </v-row>  

      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-btn
            elevation="0"
            :ripple="false"
            class="btn"
            data-cy="btn-envio"
            color="#1377F2"
            type="submit"
            :disabled="reqloading"
            :loading="reqloading"
            @click="submeterform">
            criar etapa
          </v-btn>
        </v-col>
      </v-row>    
    </form>
  </v-container>
  
</template>

<script>
import {alertar} from '@/services/notificacao.service.js'
import GadgetServico from '@/services/gadget.service.js'
import EtapaServico from '@/services/etapa.service.js'
export default {
  data(){
    return {
      nome: null,
      qtdedias: null,
      gadgets: [],
      gadgetsdb: [],
      gadget: null,
      destinos: ['Economy', 'Cliente'],
      destinosdb: [{nome: 'Economy', id: 1}, {nome: 'Cliente', id: 2}],
      destino: null,
      reqloading: false,
      notificartipos: ['Economy', 'Cliente', 'Todos'],
      notificartiposdb: [{nome: 'Economy', id: 1}, {nome: 'Cliente', id: 2}, {nome: 'Todos', id: 3}],
      notificar:null,
      tituloemail:null,
      textoemail:null,
      textobotaoacao:null,
      textourlbotao:null,
      erros: {
        nome: '',
        qtdedias: '',
        gadget: '',
        destino: null,
        notificar: null,
        tituloemail: '',
        textoemail: '',
        textobotaoacao: '',
        textourlbotao: '',
      },
    }
  },
  methods: {
    async submeterform(e){
      e.preventDefault()
      if(this.validacao()){
        this.reqloading = true
try{
          const dadosetapa = {
          nome: this.nome,
          qtdedias: this.qtdedias,
          idgadget: this.gadget === 'Nenhum' ? null : this.gadgetsdb.filter(el => el.nome === this.gadget)[0].id,
          destino: this.destinosdb.filter(el => el.nome === this.destino)[0].id, 
          notificar: this.notificartiposdb.filter(el => el.nome === this.notificar)[0].id, 
          tituloemail:this.tituloemail,
          texto:this.textoemail,
          textobotaoacao:this.textobotaoacao,
          urlredirecionamento:this.textourlbotao
        }
        console.log(dadosetapa)
        
        await EtapaServico.criar(dadosetapa)
          .then(res => {
            if(res.status === 200){
              this.reqloading = false
              alertar('success', '=)', 'Etapa criada com sucesso!')
              this.nome = null
              this.qtdedias = null
              this.gadget = null
              this.destino = null
              this.notificar = null
              this.tituloemail = null
              this.textoemail = null
              this.textobotaoacao = null
              this.textourlbotao = null
            }
          })
          .catch(e => {
            console.log('erro na criacao de etapa', e)
              this.reqloading = false

            alert('warning', '=/', 'Falha na criação da etapa!')
          })
}catch(e){
  console.log(e)
}

      }

      
    },
    validacao(){
      let objretorno
      let validado = new Array()
      if(!this.nome || this.nome.trim() === ''){
        validado.push(false)
        this.erros.nome = 'Esse campo é obrigatório'
      }
      if(!this.qtdedias || this.qtdedias.trim() === ''){
        validado.push(false)
        this.erros.qtdedias = 'Esse campo é obrigatório'
      }
      if(!this.gadget || this.gadget.trim() === ''){
        validado.push(false)
        this.erros.gadget = 'Esse campo é obrigatório'
      }
      if(!this.destino || this.destino.trim() === ''){
        validado.push(false)
        this.erros.destino = 'Esse campo é obrigatório'
      }
      if(!this.notificar || this.notificar.trim() === ''){
        validado.push(false)
        this.erros.notificar = 'Esse campo é obrigatório'
      }
      if(!this.tituloemail || this.tituloemail.trim() === ''){
        validado.push(false)
        this.erros.tituloemail = 'Esse campo é obrigatório'
      }
      if(!this.textoemail || this.textoemail.trim() === ''){
        validado.push(false)
        this.erros.textoemail = 'Esse campo é obrigatório'
      }
      // if(!this.textobotaoacao || this.textobotaoacao.trim() === ''){
      //   validado.push(false)
      //   this.erros.textobotaoacao = 'Esse campo é obrigatório'
      // }
      // if(!this.textourlbotao || this.textourlbotao.trim() === ''){
      //   validado.push(false)
      //   this.erros.textourlbotao = 'Esse campo é obrigatório'
      // }
      validado.includes(false) ? objretorno = false : objretorno = true
      return objretorno
    },
    selecionarcampo(e, type){
      if(this.erros[e.target.getAttribute('name')]){
        this.erros[e.target.getAttribute('name')] = null
      }
      if(type){
        this.erros[type] = null
      }
    },
    async requisitarGadgets(){
      let gadgets = []
      gadgets.push('Nenhum')
      await GadgetServico.buscar().then(res => {
        this.gadgetsdb = res.data
        return res.data.map(gadget => gadgets.push(gadget.nome))
      })
      this.gadgets = gadgets
    }
  },
  async mounted(){
    await this.requisitarGadgets()
  }
}
</script>

<style lang="scss" scoped>
.header{
  display: flex;
  padding: 1em;
    margin: 0 auto;
  width: 60vw;
  max-width: 100%;

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
   background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.btn-voltar{
    padding: .75em 1em;
    line-height: 1.5;
    color: white;
    font-weight: bold;
    letter-spacing: 0.02em;
    background: #1377f2;
    border-radius: 10px;
    text-transform: capitalize;
  }

.btn{
  margin-top: 1em;
  background-color: #1377F2;
  width: 100%;
  height: 3em !important;
  color: whitesmoke;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  padding: 1em;
  border-radius: 10px;
  font-weight: bold;
}
.formulario{
  margin: 0 auto 5em auto;
  width: 900px;
  max-width: 80vw;
  box-shadow:  4px 4px 20px #d9d9d9,;
  padding: 5em 1em 3em 1em;
  border-radius: 30px;
  background-color: #f9f9f2;
}

.theme--light.v-messages {
    color: red !important;
}

//css indesejados
.v-input, .v-text-field{
  outline: none !important;
}
.v-text-field__details{
  display: none !important;
}

.col-sm-6, .col-12{
  padding: 0 12px !important;
}

.v-btn:not(.v-btn--round).v-size--default {
    min-width: unset !important;
    padding: unset !important;
  }
</style>