<template>
    <CriarEtapa />
</template>

<script>
import CriarEtapa from '@/components/Etapa/CriarEtapa'
export default {
  components: {CriarEtapa},
  data(){
    return{}
  }
}
</script>

<style>

</style>